<template>
  <div>
    <v-card>
      <v-card-title class="px-4">
        <span>
          จัดการสินค้าจาก Data center เข้าร้านค้า
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isAdd = true">เพิ่มสินค้าใหม่</v-btn>
      </v-card-title>
      <v-row class="mx-1">
        <v-col cols="12" md="6">
          <h4 class="font-weight-semibold">สินค้าData center</h4>
          <v-simple-table dense>
            <thead>
            <tr>
              <th style="width:130px">
                รหัสสินค้า
              </th>
              <th>
                ชื่อสินค้า
              </th>
              <th class="text-center"  style="width: 80px;">
                เลือก
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td class="text-center" colspan="3">
                กำลังโหลดข้อมูล...
              </td>
            </tr>
            <tr v-if="!loading && !centerProductList.length">
              <td class="text-center" colspan="3">
                ไม่พบข้อมูล !
              </td>
            </tr>
            <tr v-if="!loading && centerProductList.length" v-for="(item,index) in centerProductList" :key="index">
              <td>{{ item.product_code }}</td>
              <td>{{ item.product_name }}</td>
              <td style="width: 80px;" class="text-center fixed">
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                        color='primary'
                        @click="moveOnProduct(item)"
                        icon
                        v-bind='attrs'
                        v-on='on'
                    >
                      <v-icon>{{ mdiArrowRightThick }}</v-icon>
                    </v-btn>
                  </template>
                  <span>เพิ่มสินเข้าร้านค้า</span>
                </v-tooltip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="6">
          <h4 class="font-weight-semibold">สินค้าร้านค้า</h4>
          <v-simple-table dense>
            <thead>
            <tr>
              <th style="width:130px">
                รหัสสินค้า
              </th>
              <th>
                ชื่อสินค้า
              </th>
              <th class="text-center" style="width: 40px">
                สถานะ
              </th>
              <th class="text-right" style="width: 120px">
                คงเหลือ
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td class="text-center" colspan="3">
                กำลังโหลดข้อมูล...
              </td>
            </tr>
            <tr v-if="!loading && !shopProductList.length">
              <td class="text-center" colspan="3">
                ไม่พบข้อมูล !
              </td>
            </tr>
            <tr v-if="!loading && shopProductList.length" v-for="(item,index) in shopProductList" :key="index">
              <td>{{ item.product_code }}</td>
              <td>{{ item.product_name }}</td>
              <td class="text-center" style="width: 40px">
                <StatusBlock :status="String(item.map_shop_product_active)"/>
              </td>
              <td class="text-right" style="width: 120px">
                {{ item.store_product_balance }}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card>
    <AddNewProduct v-model="isAdd" @onAdd="getProducts"/>
  </div>
</template>

<script>
import AddNewProduct from './AddNewProduct'
import {ref, onMounted} from "@vue/composition-api"
import {api} from "@/services/api"
import {mdiArrowRightThick} from "@mdi/js"
import store from "@/store"
import StatusBlock from "@/components/StatusBlock";

export default {
  props: {},
  components: {AddNewProduct, StatusBlock},
  setup(_, {root, emit}) {
    const isAdd = ref(false)
    const centerProductList = ref([])
    const shopProductList = ref([])
    const loading = ref(false)
    onMounted(() => {
      getProducts()
      getProductShop()
    })

    const getProductShop = () => {
      loading.value = true
      api.get({
        path: '/manager/mapShopProducts'
      }).then(res => {
        shopProductList.value = res.data
        loading.value = false
      }).catch(err => {
        loading.value = false
      })

    }


    const getProducts = () => {
      loading.value = true
      api.get({
        path: '/manager/mapShopProductOuters',
      }).then(({data}) => {
        centerProductList.value = data
        loading.value = false
      }).catch(err => {
        console.log("error : ", err)
        loading.value = false
      })
    }

    const moveOnProduct = (item) => {
      api.post({
        path: '/manager/mapShopProduct',
        body: {
          product_id: item.product_id,
        }
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getProducts()
        getProductShop()
      }).catch(err => {
        console.log("err : ", err)
      })
    }

    return {
      isAdd,
      centerProductList,
      moveOnProduct,
      shopProductList,
      loading,
      getProducts,
      mdiArrowRightThick,
    }
  },

}
</script>

<style scoped>

</style>
