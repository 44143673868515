<template>
  <div>
    <v-dialog v-model='isAddNewProduct' persistent max-width='800'>
      <v-card>
        <v-card-title>
          <span>เพิ่มสินค้า</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewProduct' @submit.prevent='createProduct'>
          <input
              ref='selectImage'
              type='file'
              accept='image/*'
              style='display: none'
              @change='previewFiles'
          />
          <v-row class='mx-2 my-2'>
            <v-col cols='12' class='d-flex align-center justify-center'>
              <v-hover
                  v-slot='{ hover }'
              >
                <v-img
                    v-if='blob_image'
                    contain
                    class='rounded-lg set-block'
                    :src='blob_image'
                    height='200px'
                    width='300px'
                >
                  <v-fade-transition>
                    <v-overlay
                        v-if='hover'
                        absolute
                        color='#212121'
                    >
                      <div class='align-self-center'>
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            icon
                            color='primary'
                            @click='$refs.selectImage.click()'
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              color='transparent'
                          >
                            {{ mdiPencilOutline }}
                          </v-icon>
                        </v-btn>
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            icon
                            color='primary'
                            @click="blob_image = ''"
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              color='transparent'
                          >
                            {{ mdiDeleteOutline }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
                <v-card
                    v-else
                    :elevation='hover ? 7 : 0'
                    :class="{ 'on-hover': hover }"
                    class='mx-auto upload-files '
                    height='200px'
                    outlined
                    width='300px'
                    @click='$refs.selectImage.click()'
                >
                  <div
                      align='center'
                      class='pt-12'
                  >
                    <div class='text-lg '>
                      อัพโหลดรูปภาพ
                    </div>
                    <div class='text-md error--text'>
                      ไฟล์ขนาดไม่เกิน 2 MB
                    </div>
                    <v-icon
                        x-large
                        color='primary'
                        class='pt-2 '
                    >
                      {{ mdiCloudUploadOutline }}
                    </v-icon>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols='12' md='6' class="d-flex align-center justify-center">
              <v-select :items='productCategories'
                        v-model='product_type_id'
                        item-text='product_type_name'
                        hide-details='auto'
                        no-data-text='ไม่มีข้อมูล !'
                        :loading='loadingType'
                        :rules='[required]'
                        class="me-2"
                        item-value='product_type_id'
                        dense filled label='เลือกหมวดหมู่สินค้า'
                        placeholder='เลือกหมวดหมู่สินค้า'>
              </v-select>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn v-bind='attrs'
                         v-on='on' color="primary" icon large @click="isAddProductType = true">
                    <v-icon large>{{ mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>เพิ่มหมวดหมู่สินค้า</span>
              </v-tooltip>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field filled v-model='product_code' dense label='รหัสสินค้า (SKU)'
                            :rules='[required]'
                            placeholder='รหัสสินค้า (SKU)' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6'>
              <v-text-field filled v-model='product_name' dense label='ชื่อสินค้า'
                            :rules='[required]'

                            placeholder='ชื่อสินค้า' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12' md='6' class='d-flex'>
              <v-text-field filled v-model='product_weight' dense label='น้ำหนัก'
                            :rules='[required]'
                            type="number"
                            class='pe-2'
                            placeholder='น้ำหนัก' hide-details='auto'></v-text-field>
              <v-text-field filled v-model='product_unit' dense label='หน่วย'
                            :rules='[required]'
                            placeholder='หน่วย' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12'>
              <v-textarea filled v-model='product_description' dense label='รายละเอียดสินค้า'
                          placeholder='รายละเอียดสินค้า' hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit'
                   :loading='loading'
                   :disabled='loading || !product_image.length'>บันทึก
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isAddNewProduct`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <AddNewProductCategories v-model="isAddProductType" @onAdd="getProductCategories"/>
  </div>
</template>


<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { api } from '@/services/api'
import { mdiCloudUploadOutline, mdiPencilOutline, mdiDeleteOutline, mdiPlus } from '@mdi/js'
import store from '@/store'
import AddNewProductCategories from "./AddNewProductCategories";

export default {
  components: { AddNewProductCategories },
  model: {
    prop: 'isAddNewProduct',
    event: 'update:isAddNewProduct',
  },
  props: {
    isAddNewProduct: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const product_name = ref('')
    const product_code = ref('')
    const product_weight = ref(0)
    const product_unit = ref('')
    const product_description = ref('')
    const product_image = ref([])
    const product_type_id = ref('')
    const blob_image = ref(null)
    const productCategories = ref([])
    const formAddNewProduct = ref(null)
    const isAddProductType = ref(null)
    const loading = ref(false)
    const loadingType = ref(false)

    const getProductCategories = async () => {
      loadingType.value = true
      await api.get({
        path: '/manager/productTypes',
        param: `?search=&active=1`,
      }).then(({ data }) => {
        productCategories.value = data
        loadingType.value = false
      }).catch(() => {
        loadingType.value = false
      })
    }

    const createProduct = () => {
      const isFormValid = formAddNewProduct.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('product_name', product_name.value)
      formData.append('product_code', product_code.value)
      formData.append('product_weight', product_weight.value)
      formData.append('product_unit', product_unit.value)
      formData.append('product_description', product_description.value)
      formData.append('product_type_id', product_type_id.value)
      formData.append('product_image', product_image.value[0])

      api.post({
        path: '/manager/product',
        body: formData,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewProduct', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const previewFiles = event => {
      product_image.value = []
      product_image.value.push(event.target.files[event.target.files.length - 1])
      blob_image.value = URL.createObjectURL(
          event.target.files[event.target.files.length - 1],
      )
    }

    watch(() => props.isAddNewProduct, async value => {
      if (value) {
        clearData()
        getProductCategories()

      }
    })

    const clearData = () => {
      product_name.value = ''
      product_code.value = ''
      product_weight.value = 0
      product_unit.value = ''
      product_description.value = ''
      product_image.value = []
      product_type_id.value = ''
      blob_image.value = null
    }

    return {
      product_name,
      product_code,
      previewFiles,
      formAddNewProduct,
      createProduct,
      product_weight,
      product_unit,
      product_description,
      mdiPencilOutline, mdiDeleteOutline,
      mdiCloudUploadOutline,
      blob_image,
      getProductCategories,
      productCategories,
      product_image,
      loadingType,
      loading,
      isAddProductType,
      product_type_id,
      mdiPlus,
      required,
    }
  },

}
</script>

<style scoped>
.set-block {
  max-width: 300px;
  max-height: 200px;
  border: 1px dashed #212121;
  border-radius: 10px;
  background-color: #d6d6d6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
